/* Initial state */
.arrow-animate {
  transition: all 0.5s ease-in-out;
  position: relative;
  top: 0;
  opacity: 1;
}

/* Hover state on the button */
button:hover .arrow-animate {
  animation: moveArrow 0.5s ease-in-out;
}

/* Keyframes */
@keyframes moveArrow {
  0% {
    top: 0;
    opacity: 1;
  }
  50% {
    top: -10px;
    opacity: 0;
  }
  51% {
    top: 10px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

/* contact us */

.contactus {
  border-radius: 50px;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  background: rgba(235, 235, 235);
  transition: box-shadow 0.5s ease-in-out;
}

.contactus:hover {
  border-radius: 50px;
  box-shadow: 0px 4px 24px -1px rgba(48, 155, 171, 0.2);
  background: rgba(235, 235, 235);
}

/* === Carousel Styles Start === */
/* This Carousel works dynamically with the number of images imported, check the app for more. */
/* Animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(var(--total-width) * -1)); /* Using CSS variable */
  }
}

/* Styling */
.slider {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slide-track {
  display: flex;
  gap: var(--gap); /* Using CSS variable for gap */
  animation: var(--animationspeed); /* Using CSS variable for the animation speed */
  width: calc(var(--total-width) * 2); /* Using CSS variable */
}

.slide {
  display: flex;
  align-items: center;
  width: 250px;
  height: 118px;
}
/* === Carousel Styles End === */

/* Hide both classes by default */
.fullhd,
.notfullhd {
  display: none;
}

/* Show .notfullhd for widths between 1620px and 1919px */
@media (min-width: 1620px) and (max-width: 1919px) {
  .notfullhd {
    display: block;
  }
}

/* Show .fullhd for widths greater than 1920px */
@media (min-width: 1920px) {
  .fullhd {
    display: block;
  }
}

/* topkata i ostli stvari  */
.circle-container {
  position: relative;
  width: 540px;
  height: 550px;
  overflow: visible; /* To show the blur effect outside the box */
  animation: rotate 3s infinite ease-in-out alternate;
}

.circle {
  position: absolute;
  width: 310px;
  height: 310px;
  border-radius: 50%;
  filter: blur(197px);
}

.circle1 {
  background-color: #309bab;
  top: 0;
  left: 0;
  z-index: 1;
}

.circle2 {
  background-color: #309bab;
  top: 45px;
  right: 0;
  z-index: 2;
}

.circle3 {
  background-color: #8e39ac;
  bottom: 0;
  left: 30px;
  z-index: 3;
}

/* New styles for tablet (sm to lg) */
@media (min-width: 640px) and (max-width: 1023px) {
  .circle-container {
    width: 422px;
    height: 431px;
  }

  .circle {
    width: 242px;
    height: 242px;
  }
}

/* New styles for mobile (below sm) */
@media (max-width: 639px) {
  .circle-container {
    width: 371px;
    height: 380px;
  }

  .circle {
    width: 213px;
    height: 213px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(160deg);
  }
}

.feather-blur {
  mask-image: linear-gradient(to right, transparent 0%, black 10%);
}

.animate-features {
  animation: moveUpDown2 3s infinite ease-in-out alternate;
}

/* big res animation */
.animate-hero {
  animation: moveUpDown 3s infinite ease-in-out alternate;
}

/* small res animation */
@media (max-width: 1023px) {
  /* 1px less than the lg breakpoint */
  .animate-hero {
    animation: moveUpDown-lg 3s infinite ease-in-out alternate;
  }
}

/* New keyframes */
@keyframes moveUpDown-lg {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50px);
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}

@keyframes moveUpDown2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50px);
  }
}

body {
  background-color: #e7e7e7;
}

input:focus-visible,
textarea:focus-visible {
  outline: 2px solid #309bab;
  outline-offset: -2px;
}
